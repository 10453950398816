import React from "react";
import { css } from "@emotion/core";

export default () => (
  <h1
    css={css`
      color: green;
    `}
  >
    Open Music Box
  </h1>
);
